import { graphql } from 'gatsby';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import PageSection from 'ui-kit/page-section/page-section';

import MarketingPageLayout from 'components/layouts/page/marketingpage.layout';

import { drupalMaintenanceModeContent } from 'state/drupal/drupal.selectors';

import { paragraphToComponent } from 'providers/paragraphs/paragraphs';

const MarketingLandingPageProvider = ({ data }: { data: GatsbyTypes.MarketingLandingPageDataQuery }) => {
    const metaData = data.nodeMarketingLandingPage?.field_meta_tags || {};
    const maintenanceModeContent = useSelector(drupalMaintenanceModeContent);

    const paragraphs = useMemo(() => {
        // Update data when maintenance mode changes
        const path = data.nodeMarketingLandingPage?.path?.alias;
        const nodeParagraphs = data.nodeMarketingLandingPage?.relationships?.field_landing_page_content;

        if (path === '/maintenance') {
            return nodeParagraphs?.map((item: any) => {
                const updatedItem = { ...item };

                if (updatedItem.internal && updatedItem.internal.type === 'paragraph__centered_text_hero') {
                    updatedItem.field_cth_body = maintenanceModeContent?.body;
                    updatedItem.field_cth_subtitle = maintenanceModeContent?.subtitle;
                    updatedItem.field_cth_title = maintenanceModeContent?.title;
                    updatedItem.field_cth_eyebrow = maintenanceModeContent?.eyebrow;
                }
                return updatedItem;
            });
        }

        return nodeParagraphs;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, maintenanceModeContent]);

    if (!metaData.nodeTitle && data.nodeMarketingLandingPage.title) {
        metaData.nodeTitle = data.nodeMarketingLandingPage?.title;
    }

    return (
        <MarketingPageLayout
            metaData={metaData}
            mailingAddress={data.nodeMarketingLandingPage.field_mailing_address}
            physicalAddress={data.nodeMarketingLandingPage.field_physical_address}
            specialtyMailingAddress={data.nodeMarketingLandingPage?.field_specialty_mailing_address}
            specialtyPhysicalAddress={data.nodeMarketingLandingPage?.field_specialty_physical_address}
        >
            {paragraphs?.map((paragraph, index) => (
                <PageSection key={`page_section_${index}`} index={index} paragraph={paragraph}>
                    {paragraphToComponent(paragraph?.internal.type, paragraph, index)}
                </PageSection>
            ))}
        </MarketingPageLayout>
    );
};

export default MarketingLandingPageProvider;

export const query = graphql`
    query MarketingLandingPageData($language: String!, $nid: Int) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        nodeMarketingLandingPage(drupal_internal__nid: { eq: $nid }) {
            drupal_internal__nid
            title
            field_meta_tags {
                title
                description
                abstract
                keywords
            }
            path {
                alias
            }
            field_physical_address
            field_mailing_address
            field_specialty_mailing_address
            field_specialty_physical_address
            relationships {
                field_landing_page_content {
                    ...supportedParagraphs
                    ... on paragraph__side_by_side_paragraphs {
                        id
                        relationships {
                            field_nested_paragraphs {
                                ...supportedParagraphs
                            }
                        }
                    }
                }
            }
        }
    }
`;
